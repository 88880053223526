/**
 * @generated SignedSource<<02b59a36798972e72d12c48b67989476>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaginatedProductsFilter = {
  name?: string | null;
};
export type ProductCatalog_ProductsLib_Query$variables = {
  after?: string | null;
  companyId: string;
  filter?: PaginatedProductsFilter | null;
  first?: number | null;
};
export type ProductCatalog_ProductsLib_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedProducts_ProductsLib_NodeQuery">;
};
export type ProductCatalog_ProductsLib_Query$rawResponse = {
  readonly node: {
    readonly __typename: "Company";
    readonly id: string;
    readonly paginatedProducts: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly id: string;
        };
        readonly product: {
          readonly categories: ReadonlyArray<string>;
          readonly id: string;
          readonly imageLinks: ReadonlyArray<{
            readonly url: string;
          }>;
          readonly maxPrice: {
            readonly amount: string;
          } | null;
          readonly minPrice: {
            readonly amount: string;
          } | null;
          readonly name: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type ProductCatalog_ProductsLib_Query = {
  rawResponse: ProductCatalog_ProductsLib_Query$rawResponse;
  response: ProductCatalog_ProductsLib_Query$data;
  variables: ProductCatalog_ProductsLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductCatalog_ProductsLib_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "usePaginatedProducts_ProductsLib_NodeQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductCatalog_ProductsLib_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "after",
                    "variableName": "after"
                  },
                  {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                  },
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "first"
                  }
                ],
                "concreteType": "CompanyProductsConnection",
                "kind": "LinkedField",
                "name": "paginatedProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyProductSummaryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": "product",
                        "args": null,
                        "concreteType": "ProductSummary",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Money",
                            "kind": "LinkedField",
                            "name": "minPrice",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Money",
                            "kind": "LinkedField",
                            "name": "maxPrice",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categories",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageLink",
                            "kind": "LinkedField",
                            "name": "imageLinks",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductSummary",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7806ba0a899d5465e42bcb829fe7094a",
    "id": null,
    "metadata": {},
    "name": "ProductCatalog_ProductsLib_Query",
    "operationKind": "query",
    "text": "query ProductCatalog_ProductsLib_Query(\n  $companyId: ID!\n  $filter: PaginatedProductsFilter\n  $first: Int\n  $after: String\n) {\n  ...usePaginatedProducts_ProductsLib_NodeQuery\n}\n\nfragment ProductCatalogTable_ProductsLib_CompanyProductsConnection on CompanyProductsConnection {\n  edges {\n    cursor\n    product: node {\n      id\n      name\n      minPrice {\n        amount\n      }\n      maxPrice {\n        amount\n      }\n      categories\n      imageLinks {\n        url\n      }\n    }\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n\nfragment usePaginatedProducts_ProductsLib_NodeQuery on Query {\n  node(id: $companyId) {\n    __typename\n    ... on Company {\n      paginatedProducts(after: $after, first: $first, filter: $filter) {\n        ...ProductCatalogTable_ProductsLib_CompanyProductsConnection\n        pageInfo {\n          ...usePaginatedDataFragment_pageInfo\n        }\n        edges {\n          cursor\n          node {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9a3df7f2bf241ccfb254c5a810426ed";

export default node;
